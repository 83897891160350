@charset "UTF-8";

/* -------------Sidebar----------------*/
.sidebar {
    position                    : absolute;
    width                       : 17em;
    height                      : 100%;
    top                         : 0;
    overflow                    : hidden;
    background-color            : #19222A;
    -webkit-transform           : translateZ(0);
    visibility                  : visible;
    -webkit-backface-visibility : hidden;
    display                     : flex;
    flex-direction              : column;

    header, div.side_footer {
        background-color : var(--colorPrimaryDark);
        width            : 100%;
        display          : block;
        padding          : 0.75em 1em;
        font-size        : 0.9em;
        color            : white;
        font-weight      : 600;
    }

    div.side_footer {
        background     : whitesmoke;
        vertical-align : center;
        display        : flex;
        flex-direction : row;

        div.server, div.progress {
            cursor         : pointer;
            border-radius  : 5px;
            height         : 35px;
            text-align     : center;
            line-height    : 35px;
            vertical-align : center;
        }

        div.server {
            width        : 35px;
            margin-right : 5px;

            span {
                font-size : 10px;
                color     : white;
            }

            &.on {
                background : green;
            }

            &.off {
                background : red;
            }
        }

        div.progress {
            width      : 50px;
            background : var(--colorPrimaryDark);
            position   : relative;

            & > .ui.loader {
                position : relative !important;
            }

            & > span {
                top         : 0;
                color       : white;
                font-size   : 10px;
                font-weight : bold;
                position    : absolute;
            }
        }
    }

    .sidebar-nav {
        background-color           : #19222A;
        flex                       : 1;
        font-weight                : 400;
        font-size                  : 1.2em;
        padding-bottom             : 6em;
        z-index                    : 9;
        overflow-y                 : auto;
        -webkit-overflow-scrolling : touch;

        ul {
            list-style : none;
            display    : block;
            padding    : 0;
            margin     : 0;

            li {
                margin-left  : 0;
                padding-left : 0;
                display      : inline-block;
                width        : 100%;

                a, span.link {
                    color     : rgba(255, 255, 255, 0.9);
                    font-size : 0.75em;
                    padding   : 1.05em 1em;
                    position  : relative;
                    display   : block;

                    &:hover {
                        cursor             : pointer;
                        background-color   : rgba(0, 0, 0, 0.9);
                        -webkit-transition : all 0.6s ease;
                        -moz-transition    : all 0.6s ease;
                        transition         : all 0.6s ease;
                    }

                    i {
                        font-size      : 1.8em;
                        padding-right  : 0.5em;
                        width          : 9em;
                        display        : inline;
                        vertical-align : middle;
                    }
                }
            }

            & > li {
                &:hover .nav-flyout {
                    display            : block;
                    -webkit-transition : all 0.5s ease;
                    -moz-transition    : all 0.5s ease;
                    transition         : all 0.5s ease;
                }
            }
        }

        .nav-flyout {
            position           : relative;
            background-color   : #080D11;
            z-index            : 9;
            left               : 2.5em;
            top                : 0;
            -webkit-transition : all 0.5s ease;
            -moz-transition    : all 0.5s ease;
            transition         : all 0.5s ease;
            display            : block;

            a:hover, span.link:hover {
                background-color : rgba(255, 255, 255, 0.05);
            }
        }
    }
}

.side_content {

}
